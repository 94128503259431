import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { StatusModelRef } from '@/types/status';
import { statusModel, jobListModel, jobModel } from '@/models';
import { LOCALE_MAP } from '@/i18n/localeList';

import type { JobModel, Filter } from '@/types/jobModel';

type JobStorage = {
  jobListRef: Ref<JobModel[]>;
  jobItemRef: Ref<JobModel>;
  selectedFiltersRef: Ref<Filter[]>;
  filtersRef: Ref<{ [key: string]: Filter[] }>;
};

export default function useMopJob() {
  const { $apiCompanyAws, $mopI18n } = useNuxtApp();
  const route = useRoute();

  const storage = initStorage<JobStorage>('useJob');
  const statusModelRef: StatusModelRef = ref(statusModel(null));
  const jobListRef: Ref<JobModel[]> = storage.get('jobListRef') ?? storage.saveAndGet('jobListRef', ref([]));

  const jobItemRef: Ref<JobModel> = storage.get('jobItemRef') ?? storage.saveAndGet('jobItemRef', ref(jobModel(null)));

  const selectedFiltersRef: Ref<Filter[]> =
    storage.get('selectedFiltersRef') ?? storage.saveAndGet('selectedFiltersRef', ref([]));

  const filtersRef: Ref<{ [key: string]: Filter[] }> =
    storage.get('filtersRef') ?? storage.saveAndGet('filtersRef', ref({}));
  async function fetchAllJobs() {
    if (jobListRef.value.length > 0) {
      return;
    }
    jobListRef.value = jobListModel(await $apiCompanyAws.getVacancies($mopI18n.lang)).getList();
  }

  async function fetchAllJobsSorted() {
    const allJobs: JobModel[] = jobListModel(await $apiCompanyAws.getVacancies($mopI18n.lang)).getList();
    jobListRef.value = allJobs.sort((a, b) => b.getData()!.startDate - a.getData()!.startDate);
  }

  async function fetchJob(id: string, lang?: string) {
    let jobDescriptionOriginal = '';
    jobItemRef.value = jobModel((await $apiCompanyAws.getVacancy(id, lang ?? $mopI18n.lang)).data);
    let language = LOCALE_MAP[jobItemRef.value.getCountryId()];
    if ($mopI18n.locale === 'en') {
      // Hacky way to show english translation if it exists - should probably be solved on backend side
      const temporaryJobItem = jobModel((await $apiCompanyAws.getVacancy(id, 'de')).data);
      jobDescriptionOriginal = temporaryJobItem.getDescription1();
      // If descriptions are different - that means that english translation was added in Talentsoft and we need to display it on english site only
      if (jobDescriptionOriginal !== jobItemRef.value.getDescription1() && $mopI18n.locale === 'en') {
        language = 'en';
      }
    }
    return language;
  }

  async function sendApplication(params: any) {
    statusModelRef.value = statusModel(await $apiCompanyAws.sendApplication(params));
  }

  function handleBuildUrlParams({ search, page, filter }: { search?: string; page?: number; filter?: any }) {
    const query: any = JSON.parse(JSON.stringify(route.query));
    if (filter !== undefined) {
      const key: string = Object.keys(filter)[0];
      applyFilterToQuery(query, key, filter[key]);
    }
    if (search !== undefined) {
      query.search = search;
    }
    if (page !== undefined) {
      query.page = page;
    }
    return {
      query,
    };
  }

  function applyFilterToQuery(query: any, filterName: string, value: string) {
    if (Array.isArray(query[filterName])) {
      const index: number = query[filterName].findIndex((item: any) => item === value);
      if (index === -1) {
        query[filterName].push(value);
      } else {
        query[filterName].splice(index, 1);
      }
      if (!query[filterName].length) {
        delete query[filterName];
      }
    } else if (query[filterName] === value) {
      delete query[filterName];
    } else if (query[filterName]) {
      query[filterName] = [query[filterName], value];
    } else {
      query[filterName] = value;
    }
    // spacial case, regions are shown only with country selection
    if (!query.country?.length && query.region?.length) {
      delete query.region;
    }
  }

  function isFilterApplied(filterName: string, filterValue?: string) {
    const filters: any = route.query[filterName];
    if (!filters?.length) {
      return false;
    }
    if (!filterValue) {
      return true;
    }
    return Array.isArray(filters) ? filters.includes(filterValue) : filters === filterValue;
  }

  function getSelectedFilterGroups() {
    const query: any = route.query;
    const filterItems: string[] = Object.keys(filtersRef.value);
    return Object.keys(query).filter((queryParam) => filterItems.includes(queryParam)) || [];
  }

  function getSelectedFilters() {
    const groups: string[] = getSelectedFilterGroups();
    return groups.reduce((filterList: any, group) => {
      const appliedValues = route.query[group];
      if (Array.isArray(appliedValues)) {
        appliedValues.forEach((filterValue) => {
          const filter: Filter | undefined = filtersRef.value[group]?.find(
            (filterItem) => filterItem.id === filterValue,
          );
          filter && filterList.push(filter);
        });
      } else {
        const filter: Filter | undefined = filtersRef.value[group]?.find(
          (filterItem) => filterItem.id === appliedValues,
        );
        filter && filterList.push(filter);
      }
      return filterList;
    }, []);
  }

  function addFilter(filterKey: string, id: string, name: string) {
    if (!filtersRef.value[filterKey]) {
      filtersRef.value[filterKey] = [];
    }
    const filters: Filter[] = filtersRef.value[filterKey];
    const filter: Filter | undefined = filters.find((item) => item.id === id);

    if (filter) {
      return;
    }

    filters.push({
      id,
      name,
      group: filterKey,
      count: 0,
    });
    filters.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));
  }

  function recalculateFilters() {
    Object.keys(filtersRef.value).forEach((groupKey) => {
      // reset counts
      filtersRef.value[groupKey].forEach((filter) => {
        filter.count = 0;
      });
      countFilters(groupKey);
    });
  }

  function addFilters() {
    jobListRef.value.forEach((job: JobModel) => {
      addFilter('department', job.getDepartmentId(), job.getDepartment());
      addFilter('career', job.getCareerLevelId(), job.getCareerLevel());
      addFilter('scope', job.getScopeId(), job.getScope());
      addFilter('division', job.getDivisionId(), job.getDivision());
      // disabled for now as there is only EU, enable when needed
      // addFilter('continent', job.getContinentId(), job.getContinent());
      addFilter('country', job.getCountryId(), job.getCountry());
      addFilter('region', job.getRegionId(), job.getRegion());
    });
  }

  function countFilters(groupKey: string) {
    const filterGroups: string[] = Object.keys(filtersRef.value).filter((item) => item !== groupKey);
    const jobs: JobModel[] = getFilteredJobList(null, filterGroups);
    const filters: Filter[] = filtersRef.value[groupKey];
    jobs.forEach((job: JobModel) => {
      let filterId: string;
      if (groupKey === 'department') {
        filterId = job.getDepartmentId();
      } else if (groupKey === 'continent') {
        filterId = job.getContinentId();
      } else if (groupKey === 'country') {
        filterId = job.getCountryId();
      } else if (groupKey === 'region') {
        filterId = job.getRegionId();
      } else if (groupKey === 'division') {
        filterId = job.getDivisionId();
      } else if (groupKey === 'career') {
        filterId = job.getCareerLevelId();
      } else if (groupKey === 'scope') {
        filterId = job.getScopeId();
      }
      const filter: Filter | undefined = filters.find((item) => item.id === filterId);
      if (filter) {
        filter.count++;
      }
    });
  }

  function getFilteredJobList(searchTerm?: string | null, allowedFilterGroups?: string[]) {
    const query: any = route.query;
    const search: string = searchTerm || query?.search;
    const filterGroups: string[] = getSelectedFilterGroups().filter((selectedGroup) => {
      if (!allowedFilterGroups?.length) {
        return true;
      }
      return allowedFilterGroups.includes(selectedGroup);
    });
    const containsFilters: boolean = filterGroups?.length > 0;
    if (!search && !containsFilters) {
      return jobListRef.value;
    }

    return jobListRef.value.reduce((list: JobModel[], jobItem: JobModel) => {
      const hasFilterValue: boolean =
        containsFilters &&
        filterGroups.every((filterKey) => {
          return jobItem.containsFilterValue(filterKey, query[filterKey]);
        });
      const hasSearchValue: string | boolean = search && jobItem.containsSearchTerm(search);
      if (containsFilters && search) {
        hasFilterValue && hasSearchValue && list.push(jobItem);
      } else if (containsFilters) {
        hasFilterValue && list.push(jobItem);
      } else if (search) {
        hasSearchValue && list.push(jobItem);
      }

      return list;
    }, []);
  }

  function getJobIdFromPath(path: string) {
    return /\d{4}-\d{4}$/.exec(path)?.[0] || '';
  }

  return securedWrap({
    fetchAllJobs,
    fetchAllJobsSorted,
    fetchJob,
    sendApplication,
    handleBuildUrlParams,
    applyFilterToQuery,
    isFilterApplied,
    addFilter,
    getSelectedFilterGroups,
    getSelectedFilters,
    getFilteredJobList,
    recalculateFilters,
    addFilters,
    getJobIdFromPath,
    jobListRef,
    jobItemRef,
    selectedFiltersRef,
    filtersRef,
    statusModelRef,
  });
}
